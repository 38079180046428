export const columns = [
	{
		prop: 'status',
		label: '规则状态',
	},
	{
		prop: 'type',
		label: '运营红线类型',
	},
	{
		prop: 'reason',
		label: '运营红线原因',
	},
	{
		prop: 'companyName',
		label: '所属公司',
	},
	{
		prop: 'updateTime',
		label: '创建时间',
	},
	{
		prop: 'createUserName',
		label: '创建人',
	},
	{
		prop: 'operation',
		label: '操作',
		width: '160px',
		fixed: 'right',
	},
]
export const statusList = [
	{
		name: '启用',
		code: 1,
	},
	{
		name: '禁用',
		code: 0,
	},
]
export const redLinesType = [
	{
		name: '车辆状态',
		code: 'CarStatus',
	},
	{
		name: '应收账单',
		code: 'TradeBill',
	},
	{
		name: '实收账单',
		code: 'ActualTradeBill',
	},
	{
		name: '车辆违章',
		code: 'CarViolate',
	},
]
export const filters = {
	expand: true,
	filters: [
		{
			attr: 'status',
			label: '规则状态',
			type: 'select',
			options: statusList,
			placeholder: '请选择规则状态',
		},
		{
			attr: 'type',
			label: '红线类型',
			type: 'select',
			placeholder: '请选择红线类型',
			options: redLinesType,
		},
		{
			placeholder: '请选择所属公司',
			label: '所属公司',
			attr: 'companyId',
			type: 'select',
			options: [],
			width: 250,
		},
		{
			label: '红线原因',
			attr: 'reason',
			type: 'search',
			placeholder: '输入红线原因',
			clearable: true,
		},
	],
}

export const getStatusName = code => {
	return statusList.find(i => i.code === code)?.name || '-'
}
export const getRedLineName = code => {
	return redLinesType.find(i => i.code === code)?.name || '-'
}
