<template>
	<div class="filter-item">
		<span class="filter-item-label">车牌号</span>
		<el-select-v2
			multiple
			collapse-tags
			style="width: 240px"
			placeholder="请选择车牌号"
			:options="licensePlateOptions"
			filterable
			size="default"
			@change="onSelect"
			v-model="filterParams.licensePlateNums"
		>
			<template #default="{ item }">
				<div class="custom-option">
					<span>{{ item.label }}</span>
					<span>{{ mapDict(item.status) }}</span>
				</div>
			</template>
		</el-select-v2>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import { useFetch } from '@/utils/hooks.js'
import { carSelectList } from '@/api/vehicle.js'
import { status } from '../../views/vehicle/enums'
export default defineComponent({
	props: {
		filterParams: {
			type: Object,
			default: () => ({}),
		},
		key: {
			type: String,
			default: '',
		},
		invalidList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return { licensePlateOptions: [] }
	},
	methods: {
		mapDict(value) {
			const target = status.find(item => item.code == value)
			return target ? target.name : '--'
		},
		onReset() {
			this.filterParams.licensePlateNums = null
		},
		async fetchCarList() {
			const { fetchData } = useFetch()
			const { data } = await fetchData(carSelectList, { statusList: [1, 2, 3, 4, 5, 6, 7, 8] })

			const options = data.map(item => ({
				label: item.licensePlateNum,
				value: item.licensePlateNum,
				disabled: this.invalidList.includes(item.status),
				...item,
			}))
			const valid = []
			const invalid = []
			for (const option of options) {
				if (!this.invalidList.includes(option.status)) valid.push(option)
				else invalid.push(option)
			}
			this.licensePlateOptions = valid.concat(invalid)
		},
	},
	mounted() {
		this.fetchCarList()
	},
})
</script>

<style scoped>
.filter-item {
	height: 32px;
	margin-right: 15px;
	margin-bottom: 15px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}
.custom-option {
	display: flex;
	justify-content: space-between;
}
.filter-item-label {
	font-family: PingFangSC-Regular;
	font-size: 12px;
	font-weight: 400;
	color: #a0a0a0;
	line-height: 14px;
	margin-right: 5px;
}
</style>
