import { ref, reactive, toRefs, onActivated, getCurrentInstance, onMounted, computed } from 'vue'
import { handleExport } from '@/utils/util'
import dayjs from 'dayjs'
import { useRouter, useRoute } from 'vue-router'
import { useTagStore } from '@/store/modules/tag'

function usePage(listHandler) {
	const tagStore = useTagStore()
	const router = useRouter()
	const route = useRoute()

	const isFirstInit = ref(true)

	const instance = getCurrentInstance()

	const visitedViews = computed(() => {
		return tagStore.visitedViews
	})

	const pageState = reactive({
		isLoading: false,
		filterParams: {},
		totalCount: 0,
		page: {
			current: 1,
			size: 20,
		},
		tableData: [],
	})

	function onPageChange(event) {
		pageState.page.current = event
		onRefreshData()
	}
	function onSizeChange(event) {
		pageState.page.current = 1
		pageState.page.size = event
		onRefreshData()
	}

	async function onRefreshData() {
		listHandler()
	}

	function onChangeFilter(event) {
		pageState.filterParams = {
			...event,
		}
		onRefreshData()
	}

	function onExport(title, data) {
		handleExport(data, `${title}-${dayjs().format('YYYYMMDD_HHmmss')}`)
	}

	function getHumpStr(path) {
		if (!path) return ''
		const splitStr = path.split('/')
		return splitStr[splitStr.length - 1]
			.split('-')
			.map(s => s[0].toUpperCase() + s.slice(1))
			.join('')
	}

	function onJumpToPage(to, params) {
		const index = visitedViews.value.findIndex(view => view.path === to.path)
		router.push({ path: to.path, query: params })
		if (index === -1) {
			const tag = {
				title: to.name,
				code: getHumpStr(to.path),
				path: to.path,
				isActive: true,
				noCache: false,
				affix: false,
			}
			tagStore.addTag(tag)
		}
	}

	function onManualFilter(params) {
		instance.refs.filterRef && instance.refs.filterRef.manualSetParams(params)
	}

	onActivated(() => {
		if (route.query && Object.keys(route.query).length > 0) {
			onManualFilter(route.query)
		}
		if (isFirstInit.value) {
			// instance.refs.
		}
	})

	onMounted(() => {
		if (route.query && Object.keys(route.query).length > 0) {
			onManualFilter(route.query)
		}
	})

	return {
		...toRefs(pageState),
		onRefreshData,
		onChangeFilter,
		onSizeChange,
		onPageChange,
		onExport,
		onJumpToPage,
		onManualFilter,
	}
}

export default usePage
