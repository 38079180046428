<template>
	<div class="page-container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">车辆运营红线</div>
				<div class="header-button"></div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="filterSetting" @change="onChangeFilter">
					<template #carNumber="{ filterParams: filterConfig }">
						<carNum :filterParams="filterConfig" ref="carNumRef" />
					</template>
				</yi-filter>
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					show-no
					:columns="columnSetting"
					:data="tableData"
					:params="{ page }"
				>
					<template #carNumber="{ row }">
						<el-link
							type="primary"
							style="margin-right: 15px"
							:underline="false"
							@click="onClick(row)"
						>
							{{ row.carNumber }}
						</el-link>
					</template>
					<template #type="{ row }">{{ getRedLineName(row.type) }}</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import { getRedLineName } from '../yyredrules/config'

export default defineComponent({
	name: 'OverTime',
})
</script>

<script setup name="OverTime">
import Request from '@/http'
import { inject, ref, getCurrentInstance, onMounted } from 'vue'
import { useFilter } from '@/utils/hooks.js'
import { filters, columns } from './config'
import { useRouter } from 'vue-router'
import usePage from '@/hooks/usePage'
import { getStore } from '@/utils/store'
import { companySelect } from '@/api/vehicle.js'
import carNum from '@/components/YiFilter/carNum'

const userInfo = getStore({ name: 'userInfo' })

const instance = getCurrentInstance()
const router = useRouter()
const $message = inject('$message')

const filterSetting = ref(filters)
const columnSetting = ref(columns)

const {
	isLoading,
	filterParams,
	totalCount,
	page,
	tableData,
	onRefreshData,
	onChangeFilter,
	onSizeChange,
	onPageChange,
} = usePage(queryList)

function queryList() {
	isLoading.value = true
	// const val = { ...filterParams.value }
	// val.carNumber = val.licensePlateNums
	const params = {
		page: page.value,
		...filterParams.value,
	}
	Request({
		url: 'suf4-risk-service/redLineData/paginate',
		method: 'POST',
		data: params,
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				tableData.value = res.data.records
				totalCount.value = res.data.total
				instance.refs.yiTableRef.scrollTop()
				console.log('列表获取成功 ==>', tableData)
			} else {
				isLoading.value = false
				$message.error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

function onClick(row) {
	const { carNumber, type, content } = row
	let path = '/vehicle-management',
		query = { keyword: carNumber }
	if (type === 'TradeBill') {
		path = '/driver-rent'
		query.status = '1,3'
		query.tradeBillType = content.includes('租金') ? 1 : content.includes('押金') ? 2 : ''
	} else if (type === 'ActualTradeBill') {
		path = '/driver-rent'
		query.status = '1,3'
		query.tradeBillType = 1
	} else if (type === 'CarViolate') {
		path = '/violation-summary'
	}
	router.push({ path, query })
}
function findItem(key) {
	return filterSetting.value.filters.find(item => item.attr === key)
}
async function fetchFilter() {
	const { companyId } = userInfo
	const arr = companyId ? companyId.split(',') : []
	const [companyRes] = await Promise.all([
		useFilter(() => companySelect(true), { name: 'companyName', code: 'companyId' }),
	])
	if (!companyRes.err) {
		const company = findItem('companyId')
		company.options = companyRes.data
	}
}

function initPage() {
	onRefreshData()
}

onMounted(async () => {
	fetchFilter()
	initPage()
})
</script>

<style lang="less" scoped>
.page-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.icon-plus {
		margin-right: 5px;
	}
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
}
</style>
