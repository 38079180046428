import { redLinesType } from '../yyredrules/config'
export const columns = [
	{
		prop: 'carNumber',
		label: '车牌号',
	},
	{
		prop: 'quantity',
		label: '累计数量',
		// sortable: true,
	},
	{
		prop: 'type',
		label: '运营红线类型',
	},
	{
		prop: 'reason',
		label: '运营红线原因',
	},
	{
		prop: 'content',
		label: '红线内容',
	},
	{
		prop: 'companyName',
		label: '所属公司',
	},

	{
		prop: 'assetCompanyName',
		label: '资产公司',
	},
	{
		prop: 'createTime',
		label: '创建时间',
	},
	{
		prop: 'updateTime',
		label: '更新时间',
	},
]

export const filters = {
	expand: true,
	filters: [
		{
			attr: 'type',
			label: '红线类型',
			type: 'select',
			options: redLinesType,
			placeholder: '请选择红线类型',
		},
		{
			placeholder: '请选择运营公司',
			label: '运营公司',
			attr: 'companyId',
			type: 'select',
			options: [],
			width: 250,
		},
		{
			placeholder: '请选择车牌号',
			label: '车牌号',
			attr: 'carNumber',
			type: 'select',
			isCustom: true,
			options: [],
		},
		{
			label: '红线原因',
			attr: 'reason',
			type: 'search',
			placeholder: '输入红线原因',
			clearable: true,
		},
		{
			label: '红线内容',
			attr: 'content',
			type: 'search',
			placeholder: '输入红线内容',
			clearable: true,
		},
	],
}

export const rules = {}
